import "./App.css";
import { useEffect } from "react";
import { useTelegram } from "./hooks/useTelegram";
import { Route, Routes } from "react-router-dom";

import CreateOrderPage from "./pages/CreateOrderPage/CreateOrderPage"; 

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={"order"} element={<CreateOrderPage />} /> 
      </Routes>
    </div>
  );
}

export default App;

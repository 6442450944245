import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Upload, Button, Form, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./CreateOrderPageStyles.css";

import { useTelegram } from "../../hooks/useTelegram";
import { CONFIG } from "../../constants/config";

const CreateOrderPage = () => {
  const [fileList, setFileList] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const { tg, onClose } = useTelegram();

  const chatId = searchParams.get("chat_id");
  const apiKey = searchParams.get("api_key");
  tg.MainButton.hide();

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const onSendData = (data) => {
    tg.sendData(JSON.stringify(data));
  };

  const sendFileToApi = () => {
    const data = new FormData();
    data.append(
      "media",
      JSON.stringify(
        fileList?.map((elm) => ({ type: "photo", media: elm.response }))
      )
    );
    data.append("chatId", chatId);
    data.append("description", description);
    setLoading(true);
    axios
      .post(`${CONFIG.API_URL}/order?api_key=${apiKey}`, data)
      .then(({ data: res }) => {
        onSendData(res);
        setLoading(false);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Загрузить изображение
      </div>
    </div>
  );

  return (
    <div className="ReceiptPage">
      <div className="ReceiptPage-wrapper">
        <div className="ReceiptPage-title">Создать продукт</div>
        <div className="file_upload">
          <Upload
            action={CONFIG.FILE_SERVICE_API_URL}
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            accept="image/*"
            maxCount={9}
          >
            {fileList.length >= 9 ? null : uploadButton}
          </Upload>
        </div>
        <Form layout="vertical">
          <Form.Item label="Описание">
            <Input.TextArea
              rows={6}
              name="description"
              value={description}
              onChange={(ev) => setDescription(ev.target.value)}
            />
          </Form.Item>
        </Form>
        <div className="ReceiptPage-action">
          <Button
            type="primary"
            loading={loading}
            onClick={() => sendFileToApi()}
            size="large"
            disabled={!fileList.length || !description}
            className="receipt-order"
            block
          >
            Отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateOrderPage;
